/* eslint-disable array-callback-return */
import {observable} from 'mobx';
import {toastUtil} from "../../../common/utils/ToastUtil";
import StorageService from "../../../common/service/StorageService";
import {accountService} from "../AccountService";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import {profileStore} from "../../profile/profileStore";
import {
    getExpAcount,
    isTokenExpired,
    parseJwt,
    setCookie,
    validateEmail,
    validatePhoneNumber
} from "../../../common/utils/Utils";
import Constants from "../../../common/constants/Constants";
import {inforStore} from "../../profile/infor/InforAccountStore";

class LoginStore {
    @observable public username = "";
    @observable public password = "";
    @observable public language = "";
    @observable public path = "";
    @observable public isLoadingBt: boolean = false;
    @observable public showPass: boolean = false;
    @observable public showForm: boolean = false;
    @observable public isCms: any = "";
    @observable public service: any = "";
    @observable public redirectTo: any = "";
    @observable public listAccount: any[] = [];
    @observable public isLoading: boolean = false;
    @observable public step: number = 1;
    @observable public typeShowConfirmOtp: string = '';
    @observable public resendAfter: any = 0;

    @observable public account_verify: string = localStorage.getItem('type_verify') === 'LOGIN' && localStorage.getItem('account_verify') ? String(localStorage.getItem('account_verify')) : "";
    @observable public otpCode: string = "";
    @observable public resetPassword: string = "";
    @observable public resetPasswordCofirm: string = "";


    @observable public formError: {
        name: string,
        pass: string,
        message: string,
    } = {
        name: '',
        pass: '',
        message: '',
    }
    @observable public formResetPasswordError: {
        resetPassword: string,
        resetPasswordCofirm: string,
    } = {
        resetPassword: '',
        resetPasswordCofirm: ''
    }
    @observable public formForgotPasswordError: {
        name: string,
    } = {
        name: '',
    }

    clearForm(){
        loginStore.username = ""
        loginStore.password = ""
    }

    setUserNameValue(e: any) {
         // Lấy tối đa 10 ký tự số
        loginStore.username = e.target.value.slice(0, 50); // Giới hạn độ dài của giá trị (ví dụ: 50 ký tự)
        loginStore.formError.name = ''
    }

    setPassValue(e: any) {
        loginStore.password = e.currentTarget.value;
        loginStore.formError.pass = ""
    }

    async login(redirect?: string) {
        StorageService.removeToken();

        let regexPhone: RegExp = /^(09|03|08|07|05|\+84[9|3|8|7])([0-9]{8})$/;
        let username: string = loginStore.username.replace(/\s/g, '');
        let password: string = loginStore.password;

        if (!username) {
            loginStore.formError.name = 'Email/ Số điện thoại không được để trống';
            return false;
        } else {
            if (!validateEmail(username) && (!regexPhone.test(username) || username.length < 10 || username.length > 12)) {
                loginStore.formError.name = 'Email/ Số điện thoại chưa đúng định dạng!';
                return false;
            }
        }
        if (password.length > 24 || password.length < 6) {
            loginStore.formError.pass = "Mật khẩu phải từ 6 đến 24 ký tự"
            return false;
        }


        profileStore.isLoadingLogin = true
        loginStore.showForm = true
        loginStore.isLoading = true;
        const data: any = await accountService.login(username, password, !Constants.SERVICE_LIST.includes(loginStore.service) ? 'iss' : loginStore.service);
        loginStore.isLoading = false;

        if (data.status === HttpStatusCode.OK) {
            if(loginStore.isCms && !data.body.claims.isAdmin){
                toastUtil.warning('Tài khoản này không phải admin!');
                profileStore.isLoadingLogin = false;
                return
            }
            let token: any = data.body.token;
            let avatar: any = data.body?.claims?.avatar ?? '';
            StorageService.setToken(token);
            let refreshToken: string = data.body.refreshToken;
            localStorage.setItem('dataSso', JSON.stringify({token: token, refreshToken: refreshToken}));
            setCookie('dataSso', JSON.stringify({token: token, refreshToken: refreshToken}), Constants.TOKEN_EXPIRE_DAYS);
            StorageService.setRefreshToken(refreshToken);
            toastUtil.success('Đăng nhập thành công');
            loginStore.path = `${loginStore.redirectTo ?? 'https://demo.omfarm.com.vn/'}${loginStore.isCms ? '/cms/users' : ''}?token=${token}${loginStore.service !== 'life' ? `&refreshToken=${refreshToken}` : ''}`
            parseJwt(token)
            await inforStore.getProfiles()
            loginStore.listAccount && loginStore.listAccount.map((item: any, i) => {
                if(item.phone === username){
                    loginStore.username = item.phone
                    loginStore.listAccount.splice(i, 1)
                }
            })
            loginStore.listAccount.unshift({
                name: inforStore.dataProfile?.name,
                phone: inforStore.dataProfile?.phone,
                email: inforStore.dataProfile?.email,
                userId: inforStore.dataProfile?.id,
                username: username,
                avatar: avatar,
                token: token,
                refreshToken: refreshToken,
                exp : getExpAcount(refreshToken)
            })
            localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))

            const response = {
                data : {
                    event: "ON_LOGIN",
                    ssoData: {
                        token: `${token}`,
                        refreshToken: `${refreshToken}`
                    }
                }
            };
            window.sendMobile(JSON.stringify(response));
            window.shareToken(token);
            window.postMessage({ key: 'token_sso', value: token }, loginStore.redirectTo);
            if(loginStore.redirectTo && Constants.SERVICE_LIST.includes(loginStore.service)){
                window.location.href = loginStore.path
            }
            else {
                window.location.href = `${process.env.REACT_APP_DOMAIN_SSO}/profile?username=${loginStore.username}`
            }
            window.addEventListener('load', () => {
                profileStore.isLoadingLogin = false;
            });
            loginStore.showForm = true
            loginStore.clearForm()
        }
        // else if(data.body.messageCode === "USER_NOT_VERIFIED") {
        //     profileStore.isLoadingLogin = false;
        //     localStorage.setItem('type_verify', 'LOGIN')
        //     localStorage.setItem('account_verify', loginStore.username)
        //     window.location.href = redirect ?? '/verify-otp'
        // }
        else {
            profileStore.isLoadingLogin = false;
            toastUtil.error(data.body.message, 2)
        }
    }

    async enterLogin(e: any) {
        if (e.key === 'Enter') {
            await loginStore.login()
        }
    }

    async logout(item: any){
        try {
            if(!isTokenExpired(item.exp)) {
                loginStore.listAccount.map((val, i) => {
                    if(val.username === item.username){
                        loginStore.listAccount.splice(i, 1)
                    }
                })
                localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
                loginStore.showForm = true
                loginStore.username = item.username
            }
            else {
                const resultRefreshToken = await accountService.getRefreshToken({
                    refreshToken: item.refreshToken,
                    service: !Constants.SERVICE_LIST.includes(loginStore.service) ? null : loginStore.service
                })
                if(resultRefreshToken.status === 200){
                    StorageService.setRefreshToken(resultRefreshToken.data.refreshToken)
                    StorageService.setToken(resultRefreshToken.data.token)
                    const result = await accountService.logOut(resultRefreshToken.data.token)
                    if(result.status === 200){
                        loginStore.listAccount.map((val, i) => {
                            if(val.username === item.username){
                                loginStore.listAccount.splice(i, 1)
                            }
                        })
                        localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
                    }else {
                        loginStore.showForm = true
                        loginStore.username = item.username
                    }
                }
                else {
                    loginStore.showForm = true
                    loginStore.username = item.username
                }
            }
        }
        catch (error: any) {
            if (error?.response?.data?.message) {
                toastUtil.error(error?.response?.data?.message)
            }
            loginStore.listAccount.map((val, i) => {
                if(val.username === item.username){
                    loginStore.listAccount.splice(i, 1)
                }
            })
            localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
            loginStore.showForm = true
            loginStore.username = item.username
        }


    }
    async sendOTP(service: string, redirect?: string) {
        if(!localStorage.getItem('account_verify')) return
        const data: any = {
            username: localStorage.getItem('account_verify'),
            service: service,
        }
        loginStore.isLoadingBt = true
        const res = await accountService.sendOTP(data);
        loginStore.isLoadingBt = false

        if (res.status === HttpStatusCode.OK) {
            // let dataAccountVerify  = String(localStorage.getItem('account_verify')).replace(/(\d{5})\d+(\d{2})/, "$1***$2");
            if(res.body.message && res.body.status === 406) {
                toastUtil.warning(res.body.message)
            }
            // else toastUtil.success(`Mã OTP đã được gửi về số điện thoại ${dataAccountVerify}`, 1);
            if(res.body.resendAfter) {
                loginStore.resendAfter = res.body.resendAfter
            }
            else loginStore.resendAfter = 90
            if(redirect) window.location.href = redirect
        } else {
            toastUtil.error(res.body.message ?? 'Gửi mã OTP thất bại!', 2);
        }
    }
    async verifyOTP(redirect?: string) {
        const data: any = {
            username: localStorage.getItem('account_verify'),
            otp: loginStore.otpCode,
        }
        loginStore.isLoadingBt = true
        const res = (localStorage.getItem('type_verify') === 'LOGIN') ? await accountService.verifyUser(data) : await accountService.verifyOTP(data);
        loginStore.isLoadingBt = false

        if (res.status === HttpStatusCode.OK) {
            if(localStorage.getItem('type_verify') === 'LOGIN') {
                setTimeout(() => {
                    toastUtil.success('Xác thực OTP thành công !');
                    setTimeout(() => {
                        window.location.href = redirect ?? '/login';
                    }, 1000); // Chờ 1 giây trước khi chuyển hướng
                }, 1000); // Chờ 1 giây trước khi hiển thị toast
            } else {
                setTimeout(() => {
                    toastUtil.success('Xác thực OTP thành công !');
                    setTimeout(() => {
                        window.location.href = redirect ?? '/reset-password';
                    }, 1000); // Chờ 1 giây trước khi chuyển hướng
                }, 1000); // Chờ 1 giây trước khi hiển thị toast
            }

        } else {
            toastUtil.error(res.body.message ?? 'Xác thực tài khoản thất bại', 2);
        }
        if(localStorage.getItem('type_verify') === 'LOGIN') {
            localStorage.removeItem('otp_forgot')
        }
    }
    async verifyOTPForgotPassword() {
        if (!loginStore.resetPassword.trim()) {
            loginStore.formResetPasswordError.resetPassword = 'Mật khẩu không được để trống!'
            return
        }

        if (loginStore.resetPassword.trim().length < 6 || loginStore.resetPassword.trim().length > 24) {
            loginStore.formResetPasswordError.resetPassword = 'Mật khẩu tối thiểu từ 6 đến 24 ký tự!'
            return
        } else loginStore.formResetPasswordError.resetPassword = ''

        if (!loginStore.resetPasswordCofirm.trim()) {
            loginStore.formResetPasswordError.resetPasswordCofirm = 'Mật khẩu không được để trống!'
            return
        }

        if (loginStore.resetPassword !== loginStore.resetPasswordCofirm) {
            loginStore.formResetPasswordError.resetPasswordCofirm = 'Mật khẩu không trùng khớp!'
            return
        } else loginStore.formResetPasswordError.resetPasswordCofirm = ''

        const data: any = {
            username: localStorage.getItem('account_verify'),
            password: loginStore.resetPassword,
            otp: localStorage.getItem('otp_forgot'),
        }
        loginStore.isLoadingBt = true
        const res = await accountService.forgotPassword(data);
        loginStore.isLoadingBt = false

        if (res.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật mật khẩu mới thành công');
            loginStore.typeShowConfirmOtp = "change_success"
            const tmpListAccount = loginStore.listAccount.filter(user => user.username !== localStorage.getItem('account_verify'));
            localStorage.setItem('listAccount', JSON.stringify(tmpListAccount))
            localStorage.removeItem('otp_forgot')
        } else {
            toastUtil.error(res.body.message ?? 'Cập nhật mật khẩu mới thất bại', 2);
        }
        localStorage.removeItem('otp_forgot')
    }

}


export const loginStore = new LoginStore();
